<script>
import Channels from "@/components/Channels.vue";

export default {
  name: "Redirect",
  components: {
    Channels,
  },
};
</script>

<template>
  <main>
    <Channels />
  </main>
</template>
